<template>
  <div class="article">
    <img
      :src="replace_domain(detail.headIcon)"
      class="banner"
      :style="{
        '--img_width': '100%',
        height: detail.headIconHeight,
      }"
      v-if="replace_domain(detail.headIcon)"
    />
    <div class="main">
      <div class="title cole50012">{{ detail.title }}</div>
      <div class="time">
        发表时间：{{
          detail.createTime.length == 10
            ? detail.createTime
            : detail.createTime.substring(0, 10)
        }}
      </div>
      <div v-html="replace_domain(detail.content)" class="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "articles",
  components: {},
  data() {
    return {
      detail: {
        headIcon:
          "",
        headIconHeight: "100%",
        headIconWidth: "500px",
        title: "推进数字乡村建设，助推乡村振兴",
        createTime: "2022-01-29 17:28",
        content: ``,
      },
    };
  },
  created() {
    this.getNewsDetail();
  },
  computed: {
    replace_domain() {
      return (v) => {
        return v.replace(/8.129.187.206/g, "www.hcwisdom888.com");
      };
    },
  },
  watch: {},
  methods: {
    getNewsDetail() {
      if (this.$route.query.id == undefined) return;
      this.request({
        url: this.$api.getNewsDetail,
        data: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        console.log(res, "获取新闻资讯详情");
        if (res.code == 0) {
          this.detail = res.data;
          document.title = res.data.title;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./article.less");
</style>
